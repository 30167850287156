<template>
  <div class="c-subscribed">
    <h1 class="c-subscribed__title">{{ copy.title }}</h1>
    <p>
      {{ copy.body }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Subscribed",
  computed: {
    ...mapState({
      signupMoment: state => state.signupMoment,
    }),
    copy() {
      return this.signupMoment.copy?.success
    },
    titleStyle() {
      return {
        color: this.signupMoment.buttonColor
      }
    }
  }
};
</script>

<style lang="scss">
.c-subscribed {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .o-image {
    padding-bottom: 1rem;
  }
}
</style>
