<template>
  <div v-if="!isHidden"
       class="o-signup-client"
       :class="theme"
       :style="componentStyling">
    <div class="o-content">
      <div class="o-header">
        <img alt="header-image" :src="backgroundImage" class="c-header-image">
      </div>
      <div class="o-body">
        <div class="o-body__content">
          <component :is="currentStateComponent" v-if="currentStateComponent"></component>
        </div>
      </div>
    </div>
    <div class="o-footer">
      <div v-if="copy.action" class="o-action-link" @click="openActionLink">
        {{ copy.action }}
      </div>
      <p v-if="copy.footer" class="o-legal-text">
        {{ copy.footer }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Preview from "./components/steps/Preview.vue";
import Subscribed from "./components/steps/Subscribed.vue";
import Running from "./components/steps/Running.vue";
import Expired from "./components/steps/Expired.vue";
import { config, cdnImageUrl, configuration, openLink } from '@dpgradio/creative'

export default {
  name: "App",
  components: {
    Running,
    Subscribed,
    Preview,
    Expired
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
      signupMoment: state => state.signupMoment,
      subscribed: state => state.subscribed,
      mode: state => state.mode,
    }),
    isInline() {
      return this.mode === 'inline'
    },
    theme() {
      return config('app.theme')
    },
    currentStatus() {
      return this.signupMoment.state
    },
    copy() {
      return this.signupMoment.copy?.default
    },
    currentStateComponent() {
      if (this.currentStatus === "preview") {
        return Preview
      } else if (this.subscribed) {
        return Subscribed;
      } else if (this.currentStatus === "expired") {
        return Expired
      } else if (this.currentStatus === "running") {
        return Running;
      }

      return null
    },
    isHidden() {
      return !configuration.stationId || !this.signupMoment || this.currentStatus === 'hidden' && this.mode === 'inline'
    },
    backgroundImage() {
      return cdnImageUrl(this.signupMoment?.assets?.timeline?.url)
    },
    componentStyling() {
      return {
        "--background-color": this.signupMoment.backgroundColor,
        "--text-color": this.signupMoment.textColor,
        "--button-color": this.signupMoment.buttonColor
      }
    },
  },
  methods: {
    openActionLink() {
      openLink(this.signupMoment.actionLink)
    }
  }
};
</script>
