<template>
  <button class="c-button" :disabled="disabled" @click="$emit('signup')">
    {{ copy }}
  </button>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SingupButton",
  props: {
    copy: String,
    disabled: Boolean
  },
  computed: {
    ...mapState({
      signupMoment: state => state.signupMoment,
    }),
  }
}
</script>
