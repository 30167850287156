<template>
  <form @submit.prevent="formSubmit">
    <Question v-for="question in questions"
              :key="question.id"
              :question="question"
              :radio-button-color="buttonColor"
              @input="event => updateAnswer(question, event.target.value)"
    />

    <SingupButton :copy="copy.button" class="mt" @click="formSubmit"/>
  </form>
</template>

<script>
import { mapState } from "vuex";
import SingupButton from "../SignUpButton.vue";
import Question from "./Question.vue";

export default {
  name: "QuestionForm",
  components: {
    Question,
    SingupButton,
  },
  data() {
    return {
      answers: {}
    }
  },
  computed: {
    ...mapState({
      signupMoment: state => state.signupMoment
    }),
    questions() {
      return this.signupMoment.additionalQuestions
    },
    copy() {
      return this.signupMoment.copy?.default
    },
    buttonColor() {
      return this.signupMoment.buttonColor
    },
  },
  methods: {
    updateAnswer(question, answer) {
      this.answers[question.id] = answer
    },
    formSubmit() {
      this.$emit('save', this.answers)
    }
  }
}
</script>
