<template>
  <div class="c-input-group">
    <p class="c-input-label">
      <strong>{{ question.text }}</strong>
    </p>
    <input v-if="isTextInput" type="text" :required="question.required" @input="onInput"/>
    <select v-if="isSelect" @select="onInput">
      <option v-for="(option, index) in options" :key="option" :selected="index === 0"  :value="option">{{ option }}</option>
    </select>
    <div v-if="isRadioInput">
      <label v-for="option in options" :key="option" class="c-radio-input">
        {{option}}
        <input :id="option" :name="question.id" type="radio" :value="option" @select="onInput(option)">
        <span class="checkmark" :style="radioButtonStyle"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ['question', 'radioButtonColor'],
  data() {
    return {
      answers: {}
    }
  },
  computed: {
    isSelect() {
      return this.question.kind === 'dropdown'
    },
    isTextInput() {
      return this.question.kind === 'text'
    },
    isRadioInput() {
      return this.question.kind === 'radio'
    },
    options() {
      return this.question.options.split(',').map((o) => o.trim())
    },
    canDeleteItem() {
      return this.answers.items.length > 1;
    },
    radioButtonStyle() {
      return {
        'background-color': this.radioButtonColor
      }
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>
