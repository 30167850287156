import { socket } from '@dpgradio/creative'

export default {
  namespaced: true,
  state() {
    return {
      socket: null,
    }
  },
  mutations: {
    SET_SOCKET(state, stationSocket) {
      state.socket = stationSocket
    },
  },
  actions: {
    subscribeToSocket({ dispatch, commit }, station) {
      const stationSocket = socket.connect(station)

      stationSocket
        .subscribe('app_button')
        .on('update', (data) => dispatch('setSignUpMoment', data, { root: true }), { backlog: 1 })

      commit('SET_SOCKET', stationSocket)
    },
  },
}
