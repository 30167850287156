<template>
  <div class="c-preview">
    <TimeIcon class="o-icon"/>
    <p>
      <strong>{{ copy.title }}</strong>
      <br/>
      {{ copy.body }}
    </p>
  </div>
</template>

<script>
import TimeIcon from '../../assets/icons/ic_time.svg'
import { mapState } from "vuex";

export default {
  name: "Preview",
  components: {
    TimeIcon
  },
  computed: {
    ...mapState({
      signupMoment: state => state.signupMoment,
    }),
    copy() {
      return this.signupMoment.copy?.default
    }
  },
};
</script>

<style lang="scss" scoped>
.c-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
