import { authentication } from '@dpgradio/creative'
import store from '../store'

export async function setAppCallbacks() {
  authentication.initialize()

  authentication.onRadioTokenChange(() => {
    store.dispatch('checkUserSignedIn')
  })
}
